.background-inicio {
    position: relative;
    width: 100%;
    height: 75vh; /* Ajusta este valor para cambiar la altura del contenedor */
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../assets/Portada1.png') no-repeat center center;
    background-size: cover; /* Asegura que la imagen cubra el contenedor */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Evita que el contenido sobresalga */
}

.contenedor-inicio {
    max-width: 100%; /* Ajusta el ancho máximo del contenido */
    padding: 20px; /* Espaciado interno para mejor legibilidad */
    color: rgb(36, 102, 41); /* Color del texto */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    justify-content: space-around;
    height: auto; /* Asegura que el contenido ocupe todo el alto del contenedor */
    box-sizing: border-box; /* Incluye el padding en el tamaño del contenedor */
}

.titulo-inicio {
    font-size: 3.8em;
    /* Ajusta el tamaño del título para que sea proporcional al ancho de la pantalla */
    margin-bottom: 20px;
}

.parrafo-inicio {
    font-size: 1.5em;
    font-weight: 500;
    margin: 10px;
    /* Ajusta el tamaño del párrafo para que sea proporcional al ancho de la pantalla */
}

.segundo-parrafo-inicio{
    font-size: 1.5em;
    font-weight: 500;
    margin: 10px;
}

.boton-conoce-mas {
    color: #fff;
    padding: 15px 25px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: bold;
    border: 0;
    background-color: rgb(36, 102, 41);
    cursor: pointer;
    transition: .3s;
}

/* Breakpoints para diferentes tamaños de pantalla */
@media (max-width: 1024px) {
    .titulo-inicio {
        font-size: 3em;
    }

    .parrafo-inicio {
        font-size: 1.2em;
    }

    .boton-conoce-mas {
        font-size: 1em;
        padding: 12px 20px;
    }
}

@media (max-width: 769px) {
    .background-inicio {
        height: 60vh;
    }

    .titulo-inicio {
        font-size: 2.5em;
    }

    .parrafo-inicio {
        font-size: 1.1em;
    }

    .boton-conoce-mas {
        font-size: 0.9em;
        padding: 10px 18px;
    }
}

@media (max-width: 578px) {
    .background {
        height: 50vh;
        /* Ajusta la altura del contenedor en pantallas más pequeñas */
    }

    .titulo-inicio {
        font-size: 7vw;
        /* Ajusta el tamaño del título para pantallas más pequeñas */
    }

    .parrafo-inicio {
        font-size: 4vw;
        /* Ajusta el tamaño del párrafo para pantallas más pequeñas */
    }

    .boton-conoce {
        font-size: 2.5vw;
        /* Ajusta el tamaño de la fuente del botón para pantallas más pequeñas */
        padding: 8px 16px;
        /* Ajusta el padding del botón para pantallas más pequeñas */

    }
}