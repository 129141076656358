.contenedor-footer{
    background: #e0e0e0;
    display: flex;
    flex-direction: column;
}

.contenido-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    margin: 40px;
    gap: 40px;
}

.footer-contacto-links{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 100px;
}

.metodos-contacto{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contacto-icono{
    display: flex;
}

.contacto-icono p{
    padding-left: 10px;
    font-weight: 200;
}

.links{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.links a{
    color: black;
}

.derechos-reservados{
    text-align: center;
    background: #d3d1d1;
    width: 100%;
    padding: 20px;
}

@media (max-width: 769px) {

    .footer-contacto-links{
        gap: 20px;
        flex-direction: column;
    }

    .links{
        margin-left: -40px;
    }
}