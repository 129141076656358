.politica-calidad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: #e0e0e0;
  gap: 20px;
}

.politica-calidad-imagen-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Ajusta según sea necesario */
  margin-bottom: 20px;
}

.politica-calidad-imagen {
  display: block;
  position: relative;
  width: 100%;
}

.politica-calidad-imagen-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255,255,255,0.2), rgba(224, 224, 224, 0));
}

.politica-calidad-boton {
  color: #fff;
  padding: 15px 25px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: rgb(74, 202, 29);
  background-color: rgb(36, 102, 41);
  cursor: pointer;
  transition: .3s;
  text-decoration: none;
}

.politica-calidad-boton:hover {
  background-color: rgb(74, 202, 29);
}

@media (max-width: 769px) {
  .politica-calidad-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}