.nuestros-clientes-aboutus {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;
    background-color: #ffffff;
    height: 60vh;
    flex-wrap: wrap;
}

.seccion1 {
    text-align: center;
    margin-bottom: 20px;
}

.titulo-seccion {
    color: rgb(36, 102, 41);
    font-size: 3.8em;
    font-weight: bold;
}

.separacion-vertical-aboutus {
    width: 1px;
    height: 40vh;
    /* Altura deseada */
    background-color: rgb(36, 102, 41);
    /* Color de la línea */
}

.seccion2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    /* Asegura que las imágenes no excedan el ancho del contenedor */
}

.logo-cliente {
    margin: 25px;
    width: 50px;
    /* Ajusta el tamaño de las imágenes según sea necesario */
    height: auto;
    filter: grayscale(100%);
    /* Esto hace que los logotipos sean grises como en la imagen */
    opacity: 0.3;
    /* Esto ajusta la transparencia para que los logotipos se vean como en la imagen */
    transition: opacity 0.3s ease;
    flex: 1 1 calc(25% - 50px);
    /* Permite que cada imagen ocupe el 25% del ancho del contenedor menos el margen */
    box-sizing: border-box;
}

.logo-cliente:hover{
    opacity: 0;
    filter: none;
    transition: opacity 0.3s ease;
}

.nuestros-resultados-aboutus {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    padding: 20px;
    background-color: #e0e0e0;
    height: 60vh;
    flex-wrap: wrap;
    flex-direction: column;
}

.seccion3 {
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.seccion3 p {
    margin-top: 20px;
    color: rgb(36, 102, 41);
    font-size: 1.2rem;
}

.seccion4 {
    align-items: center;
    text-align: center;
}

.estadisticas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.estadistica {
    background-color: #edf6f9;
    /* Fondo de las tarjetas */
    padding: 60px;
    width: 300px;
    /* Ancho fijo para ordenadores */
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.estadistica h3 {
    margin: 0;
    font-size: 2em;
    color: rgb(36, 102, 41)
        /* Color de los números */
}

.estadistica p {
    margin: 15px 0 0;
    font-size: 1.5em;
    color: rgb(36, 102, 41);
    /* Color del texto */
}

.sobre-nosotros-aboutus {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.seccion5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.subtitulo-seccion {
    color: rgb(36, 102, 41);
    font-size: 1.8em;
}

.separacion-horizontal-aboutus {
    width: 15%;
    /* Ajusta el ancho según sea necesario */
    height: 3px;
    /* Ajusta el grosor de la línea */
    background-color: rgb(36, 102, 41);
    /* Color de la línea */
    margin: 25px;
    /* Espaciado alrededor de la línea */
}

.parrafo-bienvenida {
    color: rgb(36, 102, 41);
    font-size: 20px;
    width: 50%;
    margin: 20px;
}

.seccion6 {
    display: flex;
    justify-content: center;
}

.aboutUs-figura {
    border: 3px solid #000; /* Cambia el color y grosor del borde según sea necesario */
}

.video-presentacion-aboutus{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    padding: 20px;
    background-color: #e0e0e0;
}

.video-contenedor {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.video {
    width: 100%;
    max-width: 900px; /* Ajusta según tus necesidades */
    border: 2px solid #ddd;
    border-radius: 8px;
}

@media (max-width: 1365px) {
    .separacion-vertical-aboutus {
        display: none;
    }
}

/* Media query para pantallas más pequeñas que 916px */
@media (max-width: 916px) {
    .nuestros-resultados-aboutus {
        flex-direction: column;
        /* Asegura que siga siendo una columna */
        height: auto;
        /* Ajusta la altura para que se adapte al contenido */
    }

    .estadisticas {
        flex-direction: column;
        /* Asegura que las estadísticas se apilen verticalmente */
    }

    .estadistica {
        width: 100%;
        /* Asegura que las tarjetas ocupen todo el ancho disponible */
        margin-bottom: 20px;
        /* Añade espacio entre las tarjetas */
    }
}

@media (max-width: 769px) {
    .sobre-nosotros-aboutus{
        display: flex;
        flex-direction: column;
    }

    .aboutUs-figura{
        width: 100%; /* Ajusta el ancho de la imagen según sea necesario */
        max-width: 300px; /* Define un ancho máximo para mantener la proporción */
    }

    .nuestros-clientes-aboutus {
        flex-direction: column;
        height: auto; /* Ajusta la altura para adaptarse al contenido */
    }

    .seccion1 {
        margin-bottom: 20px; /* Añade espacio entre el título y las imágenes */
    }

    .seccion2 {
        justify-content: center; /* Centra las imágenes */
    }

    .logo-cliente {
        flex: 1 1 calc(50% - 50px); /* Cada imagen ocupa el 50% menos el margen */
    }

    .separacion-vertical-aboutus {
        display: none; /* Oculta la línea vertical */
    }

    .estadisticas {
        flex-direction: column; /* Estadísticas apiladas verticalmente */
    }

    .estadistica {
        width: 100%; /* Ancho completo para cada tarjeta */
        margin-bottom: 20px; /* Espacio entre tarjetas */
        padding: 20px; /* Ajusta el padding para mejor legibilidad */
    }

    .seccion5,
    .seccion6 {
        text-align: center; /* Centra el contenido */
    }

    .parrafo-bienvenida {
        width: 100%; /* Ancho completo para el párrafo */
        margin: 20px 0; /* Espaciado */
    }

    .aboutUs-figura {
        max-width: 100%; /* Asegura que la figura se ajuste al ancho del contenedor */
    }

    .video-presentacion-aboutus {
        flex-direction: column; /* Asegura que el video y su contenedor se apilen verticalmente */
        height: auto; /* Ajusta la altura para adaptarse al contenido */
    }

    .video-contenedor {
        margin: 20px 0; /* Espaciado */
    }

    .video {
        max-width: 100%; /* Asegura que el video se ajuste al ancho del contenedor */
    }
}

@media (max-width: 480px) {
    .estadistica {
        width: 100%;
        /* Ancho completo para smartphones */
        margin: 0 0 20px;
        /* Espacio inferior entre elementos */
    }
}