/* components/WhatsappButton.css */
.whatsapp-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: .3s;
}

.whatsapp-button img {
    width: 100%;
    height: auto;
    border-radius: 60%;
}

.whatsapp-button:hover{
    transform: translateY(-20%);
    transition: transform .3s;
}

.whatsapp-dialog {
    position: fixed;
    bottom: 90px;
    /* Ajusta esta distancia según sea necesario */
    right: 30px;
    background-color: #f1f9f9;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-size: 1.2em;
    color: #333;
    max-width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 2s ease-out, transform 2s ease-out;
}

.whatsapp-dialog.animated {
    opacity: 1;
    transform: translateY(0);
}

.whatsapp-dialog::before {
    content: '';
    position: absolute;
    bottom: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
}

.close-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

@keyframes bounce{
    20%, 50%, 80%, to{
        transform: translateY(0);
    }
    40%{
        transform: translateY(-30px);
    }
    70%{
        transform: translateY(-30px);
    }
    90%{
        transform: translateY(-30px)
    }
}
