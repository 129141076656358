.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 700px;
    max-width: 100%;
    z-index: 1000;
    text-align: center; /* Para centrar el texto del título */
    display: flex; /* Para alinear la imagen y el formulario en línea */
    gap: 20px; /* Espacio entre la imagen y el formulario */
    transition: opacity 1s ease-out;
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.blur{
    filter: blur(5px);
    transition: filter .7s;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.image-container {
    display: flex;
    align-items: center; /* Centra la imagen verticalmente */
    justify-content: center; /* Centra la imagen horizontalmente */
}

.image-container img {
    max-width: 250px;
    height: auto;
    box-shadow: 0 5px 10px rgba(36,102,41,1);
    border-radius: 8px;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.separacion-horizontal-modal-form{
    width: 100%;
    height: 2px;
    background-color: rgb(36, 102, 41);
}

.titulo-form{
    font-size: 2em;
    color: rgb(36, 102, 41);
}

.form-group {
    margin-bottom: 15px;
    text-align: left; /* Asegura que las etiquetas e inputs estén alineados a la izquierda */
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: rgb(36, 102, 41);
    font-weight: 600;
}

.form-group input {
    width: calc(100% - 20px);
    padding: 8px 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-group {
    display: flex;
    justify-content: center; /* Centra los botones */
    gap: 10px; /* Añade espacio entre los botones */
}

.button-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-submit {
    background-color: rgb(36, 102, 41);
    color: white;
    transition: background-color 0.3s;
    text-transform: uppercase;
    font-weight: 600;
}

.button-submit:hover {
    background-color: rgb(74, 202, 29);
}

.button-close {
    background-color: #ccc;
    color: #333;
    transition: background-color 0.3s;
    text-transform: uppercase;
    font-weight: 600;
}

.button-close:hover {
    background-color: #bbb;
}

@media (max-width: 760px) {
    .Modal{
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .separacion-horizontal-modal{
        width: 80%;
        background-color: rgb(36, 102, 41);
        height: 2px;
    }

    .image-container img {
        max-width: 180px;
        height: auto;
        box-shadow: 0 5px 10px rgba(36,102,41,1);
        border-radius: 8px;
    }
}
