.servicios-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.primer-seccion-servicios{
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.titulo-descripcion-servicios{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.servicios-titulo {
  font-size: 3.8em;
  color: rgb(36, 102, 41);
  margin-bottom: 10px;
}

.servicios-descripcion {
  font-size: 1.2em;
  color: rgb(36, 102, 41);
  margin-bottom: 30px;
}

.servicios-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  text-align: center;
}

.servicio-item {
  background-color: #f1f1f1;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.servicio-imagen {
  width: 100%;
  height: auto;
  display: block;
}

.servicio-item h2 {
  color: rgb(36, 102, 41);
  font-size: 1.5em;
  margin: 15px 0;
}

.servicio-item p {
  color: rgb(36, 102, 41);
  font-size: 1em;
  line-height: 1.6;
  padding: 0 15px 15px 15px;
}

/* Responsivo */
@media (max-width: 769px) {

  .titulo-seccion{
    font-size: 3em;
  }

  .servicios-titulo {
    font-size: 2em;
  }

  .servicios-descripcion {
    font-size: 1em;
  }

  .servicio-item h2 {
    font-size: 1.3em;
  }

  .servicio-item p {
    font-size: 0.9em;
  }
}
